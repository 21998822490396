<script setup lang="ts">
import { computed } from "vue";
import { LoadingSpinner } from "@wegift/two-components";
import { useI18n } from "vue-i18n";
const { t } = useI18n();

const props = defineProps<{
  translationsLoaded: boolean;
}>();
</script>

<template>
  <LoadingSpinner />
  <p v-if="translationsLoaded" class="font-heading mt-4 text-xl font-extrabold">
    {{ $t("message.loading_balance") }}
  </p>
</template>
